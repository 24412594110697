import wood from '../img/wood.jpg';
import raps from '../img/raps.jpg';
import cloudmachine from '../img/cloudmachine.jpg';
import darss from '../img/darss.jpg';
import autum from '../img/autum.jpg';

const body = document.body;
const shell = document.getElementById('shell');

const images: string[] = [
    wood,
    raps,
    cloudmachine,
    darss,
    autum
];

let image = images[Math.floor(Math.random() * images.length)];
body.style.backgroundImage = "url(" + image + ")";
console.log(image);

let string = '';
string += " ____  _                     ____       _          \n";
string += "|  _ \\(_)_ __   __ _  ___   | _  \\ ___ | |__   ___\n";
string += "| |_) | | '_ \\ / _` |/ _ \\  | |_) / _ \\| '_ \\ / _ \\\n";
string += "|  _ <| | | | | (_| | (_) | |  _ < (_) | | | |  __/\n";
string += "|_| \\_\\_|_| |_|\\__, |\\___/  |_| \\_\\___/|_| |_|\\___|\n";
string += "               |___/\n";
string += "                         --------------Webdeveloper\n";
string += "\n";
string += "Hi! I'm Ringo. Great you've found me.\n";
string += "If you want to reach out, here are a few ways to contact:\n";
string += "\n";
string += "\n";
string += "  > E-Mail: hallo@ringorohe.de\n";
string += "  > Mastodon: <a href=\"https://mastodon.online/@errpunkterr\">mastodon.online/@errpunkterr</a>\n";
string += "  > Web: <a href=\"https://ringorohe.de\">ringorohe.de</a>\n";
string += "  > Github: <a href=\"https://github.com/RingoRohe/\">github.com/RingoRohe/</a>\n";
string += "  > Blog: <a href=\"https://blog.ringorohe.de\">blog.ringorohe.de</a>\n";
string += "  > Foto: <a href=\"https://foto.ringorohe.de\">foto.ringorohe.de</a>\n";

// string = string.replaceAll(' ', '&nbsp;');
// string = string.replaceAll('\n', '<br>');

if (shell) {
    shell.innerHTML = string;
}